import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 5%;
  top: 23px;

  svg{
    width: 60px;
    height: 48px;
    cursor: pointer;
    outline-style: none; 
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  svg path{
    stroke-width: 25;
    stroke-linecap: square;
    stroke: #fff;
    fill: none;
    transition: all .5s ease-in-out;
    pointer-events: none;
  }

  /* In menu form */

  .top_bar,
  .bottom_bar{
    stroke-dasharray: 280px 910px;
  }

  .middle_bar{
    stroke-dasharray: 280px 240px;
  }

  /* In cross form */

  .open .top_bar,
  .open .bottom_bar{
    stroke-dashoffset: -674px;
  }

  .open .middle_bar{
    stroke-dasharray: 0px 220px;
    stroke-dashoffset: -150px;
  }
`;

class BurgerMenu extends Component {
  toggleMenu(elm) {
    elm.classList.toggle('open');
    this.props.toggleOverlay();
  }

  render() {
    return (
      <Wrapper>
        <svg viewBox="0 0 800 600" class="" onClick={(e) => { this.toggleMenu(e.target); }}>
          <path d="M280,220c0,0,260,0,280,0c200,0,80,320-40,200c-80-80-220-220-220-220" class="top_bar" />
          <path d="M280,320h280" class="middle_bar" />
          <path d="M280,428c0,0,260,0,280,0c200,0,80-320-40-200c-80,80-220,220-220,220" class="bottom_bar" />
        </svg>
      </Wrapper>
    );
  }
}
export default BurgerMenu;