import React, { Component } from 'react';
import './index.css';
import styled from 'styled-components';
import BurgerMenu from './BurgerMenu';

const backgroundImage = require('./assets/bg.jpg');
const logo = require('./assets/logo.svg');
const menu = require('./assets/menu.svg');

const Background = styled.div`
  position: absolute;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  width: 100%;
  height: 100vh;
  min-height: 500px;
  background-color: #000;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgb(7, 33, 55);
`;

const Wrapper = styled.div`
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
`;

const Header = styled.div`
  /* height: 75px; */
`;

const Logo = styled.div`
  position: absolute;
  z-index: 2;
  margin: 30px 5%;
  float: left;
`;

const Body = styled.div`
  color: #ffffff;
  text-align: center;
  margin-top: 28vh;
`;

const Title = styled.p`
  font-family: 'IBM Plex Serif', serif;
  font-weight: 200;
  font-size: 40px;
  margin: 0 5% 0 5%;
  line-height: 1.1em;
`;

const Domain = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 30px;
  display: inline-block;
  margin: 10px 0 5vh 0;
`;

const Info = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4em;
  /* padding-bottom: 4vh; */
`;

const Link = styled.p`
  position: absolute;
  z-index: 2;
  font-family: 'IBM Plex Serif', serif;
  font-size: 30px;
  color: #fff;
  top: calc(15vh + ${props => props.index}*10vh);
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
`;

class App extends Component {
  constructor() {
    super();
    this.overlay = React.createRef();
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  toggleOverlay() {
    this.overlay.current.classList.toggle('open');
  }

  render() {
    return (
      <Background>
        <Overlay ref={this.overlay}>
          <Link index={1}><a href="http://cphcloud.com">Home</a></Link>
          <Link index={2}><a href="http://cphcloud.com/om-os">About</a></Link>
          <Link index={3}><a href="http://cphcloud.com/cases">Cases</a></Link>
          <Link index={4}><a href="http://cphcloud.com/kontakt">Contact</a></Link>
        </Overlay>
        <Wrapper>
          <Header>
            <Logo><a href="http://www.cphcloud.com"><img src={logo} alt="cphcloud" /></a></Logo>
            <BurgerMenu toggleOverlay={this.toggleOverlay} />
          </Header>
          <Body>
            <Title className="title">This domain is hosted by CPH Cloud</Title>
            <Domain>{window.location.hostname}</Domain>
            <Title className="title">Contact</Title>
            <Info>
              CPH Cloud Company<br />
              Dampfærgevej 8<br />
              2100 Copenhagen Ø, Denmark<br />
              <br />
              Tlf: 70 25 68 39<br />
              mail@cphcloud.com<br />
              <a href="http://www.cphcloud.com">www.cphcloud.com</a>
            </Info>
          </Body>
        </Wrapper>
      </Background>
    );
  }
}
export default App;